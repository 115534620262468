var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-container"},[_c('div',{staticClass:"header"},[(_vm.exercisesToDo && _vm.exercisesToDo.length > 0)?_c('div',{staticClass:"exit-container"},[(_vm.isLoggedIn)?_c('router-link',{attrs:{"to":{ name: 'home'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"href":href}},[_c('font-awesome-icon',{staticClass:"times",attrs:{"icon":"times"}})],1)]}}],null,false,2110486362)}):_vm._e(),(!_vm.isLoggedIn)?_c('router-link',{attrs:{"to":{ name: 'login'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"href":href}},[_c('font-awesome-icon',{staticClass:"times",attrs:{"icon":"times"}})],1)]}}],null,false,2110486362)}):_vm._e()],1):_vm._e(),(_vm.exercisesToDo && _vm.exercisesToDo.length > 0)?_c('ProgressBar',{attrs:{"progress":_vm.progress,"width":"85%","height":"1.5em","position":"relative","borderRadius":"0.35em","background":"white","color":"linear-gradient(50deg, #36B37E 0, #32de4f 100%)"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }