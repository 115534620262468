var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('div',{staticClass:"question-content"},[(_vm.question.noPictures)?_c('h1',[_vm._v(" Choisissez la bonne traduction ")]):_vm._e(),(!_vm.question.noPictures && !_vm.question.direction)?_c('h2',[_vm._v(" Comment dit-on « "+_vm._s(_vm._f("capitalize")(_vm.question.text))+" » ? ")]):_vm._e(),(!_vm.question.noPictures && _vm.question.direction)?_c('h2',[_vm._v(" Quelle est la traduction de « "+_vm._s(_vm._f("capitalize")(_vm.question.text))+" » ? ")]):_vm._e(),(_vm.question.noPictures)?_c('h2',[_vm._v(" « "+_vm._s(_vm._f("capitalize")(_vm.question.text))+" » ")]):_vm._e(),_c('div',{staticClass:"proposition-container",class:{
          'with-picture': !_vm.question.noPictures,
          'without-picture': _vm.question.noPictures,
       }},_vm._l((_vm.shuffledPropositions),function(proposition,i){return _c('button',{key:proposition.id,staticClass:"proposition",class:{
              selected: proposition.id === _vm.selectedPropositionId,
              'right-answer': _vm.currentCorrection && proposition.id === _vm.currentCorrection.correctAnswer,
              'wrong-answer': _vm.currentCorrection && proposition.id === _vm.selectedPropositionId && _vm.currentCorrection.correctAnswer !== _vm.selectedPropositionId,
              'with-picture': !_vm.question.noPictures,
            },attrs:{"type":"button","disabled":_vm.currentCorrection},on:{"focus":_vm.resetButtonFocus,"click":function($event){return _vm.selectProposition(proposition.id)}}},[(!_vm.question.noPictures)?_c('img',{attrs:{"src":proposition.image}}):_vm._e(),_c('div',{staticClass:"text-container"},[_c('span',{staticClass:"shortcut-indicator"},[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(proposition.text))+" ")])])])}),0),(_vm.user.superAdmin)?_c('div',{staticClass:"super-edit"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.question.text),expression:"question.text"}],staticStyle:{"width":"100%"},domProps:{"value":(_vm.question.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.question, "text", $event.target.value)}}}),_c('hr'),_vm._l((_vm.question.propositions),function(proposition){return _c('div',{key:proposition.id},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(proposition.text),expression:"proposition.text"}],staticStyle:{"width":"100%"},domProps:{"value":(proposition.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(proposition, "text", $event.target.value)}}})])}),_c('br'),_c('button',{on:{"click":_vm.pushUpdate}},[_vm._v("go")])],2):_vm._e(),_c('button',{staticClass:"submit",attrs:{"type":"button","disabled":!_vm.selectedPropositionId},on:{"click":_vm.submit}},[_vm._v(" Valider ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }